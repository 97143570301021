import { useState } from "react";
import {
  Typography,
  Card,
  Input,
  CardBody,
  CardHeader,
  CardFooter,
  Button
} from "@material-tailwind/react";

const defaultContactData = {
  first_name: "",
  last_name: "",
  email: "",
  tel: "",
  address: "",
  title: "",
  org: "",
  url: "",
  linkedin: "",
};

const WebsiteDataCard = ({setData}) => {

    const [ contactData, setContactData ] = useState(defaultContactData);

    const onGenerateQR = () => {
        const vCard = generateVCard(contactData);
        setData(vCard);
    };

    const generateVCard = (data) => {

    const vCard = `BEGIN:VCARD
VERSION:3.0
FN:${data.first_name} ${data.last_name}
N:${data.last_name};${data.first_name};;;
EMAIL;type=WORK,INTERNET:jason@fl1.digital
TEL;TYPE=WORK,VOICE:${data.tel}
TITLE:${data.title}
ORG:${data.org}
URL;type=WORK:${data.url}
X-SOCIALPROFILE;TYPE=linkedin:${data.linkedin}
REV:2023-11-21T11:31:30.171Z
END:VCARD
`;
console.log(vCard);
return vCard;
  }

  const handleChange = (e) => {
      setContactData({ 
          ...contactData, 
          [e.target.id]: e.target.value
      });
  };

  return (
    <Card className="w-full p-5 shadow-none">
        <CardHeader color="blue-gray" content="QR Code Data" />
        <CardBody>
        
        <div className="flex flex-row mb-3">
            <div className="flex flex-col w-1/2">
                <Typography color="blue-gray" className="mb-3">
                    First Name:
                </Typography>

                <Input
                    size="md"
                    id="first_name"
                    placeholder="John"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900 mb-3"
                    onChange={handleChange}
                    value={contactData.first_name}
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
            </div>
            <div className="flex flex-col w-1/2 ml-3">
                <Typography color="blue-gray" className="mb-3">
                    Surname:
                </Typography>

                <Input
                    size="md"
                    id="last_name"
                    placeholder="Smith"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900 mb-3"
                    onChange={handleChange}
                    value={contactData.last_name}
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
            </div>
        </div>  

        <div className="flex flex-row mb-3">
            <div className="flex flex-col w-1/2">
                <Typography color="blue-gray" className="mb-3">
                    Email Address:
                </Typography>

                <Input
                    size="md"
                    type="email"
                    id="email"
                    placeholder="smith@google.com"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    onChange={handleChange}
                    value={contactData.email}
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
            </div>
            <div className="flex flex-col w-1/2 ml-3">
                <Typography color="blue-gray" className="mb-3">
                    Phone Number:
                </Typography>

                <Input
                    size="md"
                    type="tel"
                    id="tel"
                    placeholder="+44 (0)1727 739812"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    onChange={handleChange}
                    value={contactData.tel}
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
            </div>
        </div>

        <div className="flex flex-row mb-3">
            <div className="flex flex-col w-1/2">
                <Typography color="blue-gray" className="mb-3">
                    Company Name:
                </Typography>
        
                <Input
                    size="md"
                    id="org"
                    placeholder="My Company Inc."
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    onChange={handleChange}
                    value={contactData.org}
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
            </div>
            <div className="flex flex-col w-1/2 ml-3">
                <Typography color="blue-gray" className="mb-3">
                    Job Title:
                </Typography>

                <Input
                    size="md"
                    id="title"
                    placeholder="Company Director"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    onChange={handleChange}
                    value={contactData.title}
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
            </div>
        </div>

        <div className="flex flex-row mb-3">
            <div className="flex flex-col w-1/2">

                <Typography color="blue-gray" className="mb-3">
                    Website:
                </Typography>

                <Input
                    size="md"
                    id="url"
                    type="url"
                    placeholder="https://www.google.com"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    onChange={handleChange}
                    value={contactData.url}
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
            </div>
            <div className="flex flex-col w-1/2 ml-3">

                <Typography color="blue-gray" className="mb-3">
                    LinkedIn Profile:
                </Typography>
        
                <Input
                    size="md"
                    id="linkedin"
                    type="url"
                    placeholder="https://www.linkedin.com/in/johnsmith/"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    onChange={handleChange}
                    value={contactData.linkedin}
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
            </div>
        </div>
        
        </CardBody>
        <CardFooter>
            <div className="flex justify-end">
                <Button color="red" onClick={onGenerateQR}>Generate QR Code</Button>
            </div>
        </CardFooter>
    </Card>
  )
}

export default WebsiteDataCard