import { useEffect } from "react";
import GeneratorView from "./components/GeneratorView";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  
  useEffect(() => {
  
  }, []);

  return (  
    <Router>
      <Routes>
        <Route path="/" element={<GeneratorView />} />
      </Routes>
    </Router>
  );
}

export default App;
