import { useState } from "react";
import QRCode from "react-qr-code";
import Branding from "./Branding";

import {
  Button,
  Radio,
} from "@material-tailwind/react";

import WebsiteDataCard from "./WebsiteDataCard";
import ContactDataCard from "./ContactDataCard";

const GeneratorView = () => {

  const [qrForContact, setQrForContact] = useState(true);
  const [qrData, setQrData] = useState("");

  const svgToPng = ()=>{
    const mySvg = document.getElementById('qr-code');    

    const url = getSvgUrl(mySvg.outerHTML);
    svgUrlToPng(url, (imgData) => {
        //setQrImage(imgData);

        var link = document.createElement('a');
        link.href = imgData;
        link.download = 'MyQRCode.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(url);
    });
  };

  const getSvgUrl = (svg) =>{
    return  URL.createObjectURL(new Blob([svg], { type: 'image/svg+xml' }));
  }

  const svgUrlToPng = (svgUrl, callback) => {
    const svgImage = document.createElement('img');
    //svgImage.style.display = 'none';
    svgImage.style.position = 'absolute';
    svgImage.style.top = '-9999px';
    document.body.appendChild(svgImage);
    svgImage.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = svgImage.clientWidth;
        canvas.height = svgImage.clientHeight;
        const canvasCtx = canvas.getContext('2d');
        canvasCtx.drawImage(svgImage, 0, 0);
        const imgData = canvas.toDataURL('image/png');
        callback(imgData);
        // document.body.removeChild(imgPreview);
    };
    svgImage.src = svgUrl;
 }

  return (
    <main className="flex items-center justify-center h-screen min-h-[450px]">
      <div className="container flex flex-col sm:flex-row border h-5/6 min-h-[400px] rounded-2xl drop-shadow-xl">
        
        <div className="p-4 text-white  bg-white border rounded-l-lg md:w-3/4">
          <div className="flex flex-col text-black items-center justify-center mb-2">

            <div className="flex gap-10">
                <Radio name="type" label="Website URL" checked={!qrForContact} onClick={()=>setQrForContact(false)} />
                <Radio name="type" label="Contact VCard" checked={qrForContact} onClick={()=>setQrForContact(true)}/>
            </div>
            {qrForContact ? 
            <ContactDataCard data={qrData} setData={setQrData} />
            :
            <WebsiteDataCard data={qrData} setData={setQrData} />
            }
          </div>
          
          <div className="absolute top-0 right-0  h-[120px] w-[120px] overflow-hidden">
            <Branding />
          </div>
        </div>
        <div className="flex flex-col p-4 bg-white border rounded-r-lg sm:w-1/3 min-w-min">
          <div className="flex items-center justify-center mb-2">
            <img
              src="https://hub.fl1digital.com/wp-content/uploads/2018/04/fl1-logo.png"
              className="w-[150px]"
            />
          </div>
          <hr className="" border="true" />
          <div className="flex flex-col items-center justify-center mb-2 h-3/4">
            {qrData && 
            <div className="flex flex-col">
              <QRCode
                size={256}
                id="qr-code"
                style={{
                  height: "auto",
                  maxWidth: "100%",
                  width: "yarn 100%",
                  backgroundColor: "red",
                }}
                viewBox={`0 0 256 256`}
                value={qrData}
              />
              <Button className="mt-2" onClick={() => svgToPng()}>Download</Button>
            </div>
            }
          </div>
        </div>
      </div>
    </main>
  );
};

export default GeneratorView;
