const Branding = () => {
  return (
    <div className="relative top-[20px] left-[-10px] z-10 w-[185px] py-1 px-0 shadow-[0_2px_4px_0_rgba(0,0,0,0.2)] font-bold text-center transition duration-150 ease-out hover:ease-in rotate-[48deg] bg-[#505960]">
      <div className="ml-0 text-[8px] uppercase">powered by</div>
      <div className="ml-2 text-[14px] ">FL1 Digital</div>
    </div>
  );
};

export default Branding;
