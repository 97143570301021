import { useState } from "react";
import {
  Typography,
  Card,
  Input,
  CardBody,
  CardFooter,
  Button
} from "@material-tailwind/react";

import ErrorMessageText from "./ErrorMessageText";

const WebsiteDataCard = ({data, setData}) => {

    const [ urlData, setUrlData ] = useState(data.url);
    const [ errors, setErrors ] = useState({});

    const onGenerateQR = () => {
        
        if (!validateUrl(urlData)) {
            setErrors({ url: "Please enter a valid URL" });

            // Remove error messages after 5 seconds
            setTimeout(() => {
                setErrors({});
            }, 2000);
            return;
        }
        else {
            setErrors({});
            setData(urlData);
        }
    };

    const validateUrl = (url) => {
        const urlRegex = new RegExp(
            "^(https?:\\/\\/)?"+ // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|"+ // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))"+ // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*"+ // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?"+ // query string
            "(\\#[-a-z\\d_]*)?$",
            "i"
        );
        return urlRegex.test(url);
    }

    const handleChange = (e) => {
        setUrlData(e.target.value);
    };

  return (
    <Card className="w-full p-5 shadow-none">
        <CardBody>
        <Typography variant="h6" color="blue-gray" className="mb-3">
            Link to to your website
        </Typography>
        
        <Typography color="blue-gray" className="mb-3">
            Website URL:
        </Typography>

        <Input
            size="md"
            id="name"
            placeholder="John Smith"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            onChange={handleChange}
            value={urlData}
            labelProps={{
                className: "before:content-none after:content-none",
            }}
        />
        <ErrorMessageText message={errors.url} />
        </CardBody>
         <CardFooter>
            <div className="flex justify-end">
                <Button color="red" onClick={onGenerateQR}>Generate QR Code</Button>
            </div>
        </CardFooter>
    </Card>
  )
}

export default WebsiteDataCard